<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('AccountWorkSchedules')"
    @cancel="onCancel('AccountWorkSchedules')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'AccountWorkScheduleCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'AccountWorkSchedules',
      pageHeader: 'Создание рабочего времени сотрудника',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Рабочее время сотрудников',
          route: { name: 'AccountWorkSchedules' },
        },
        {
          text: 'Создание рабочего времени сотрудника',
        },
      ],
      initialForm: {
        accountId: null,
        beginWork: null,
        endWork: null,
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      employeeAccounts: (state) => state.common.employeeAccounts,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'select',
              key: 'accountId',
              label: 'Сотрудник',
              list: this.employeeAccounts.data,
            },
            {
              type: 'fullDateTime',
              key: 'beginWork',
              label: 'Дата и время начала работы',
              attributes: {
                format: 'DD.MM.YYYY HH:mm',
                valueType: 'DD.MM.YYYY HH:mm',
                timeTitleFormat: 'DD.MM.YYYY HH:mm',
                disabledDate: this.beginWorkDateDisabled,
                disabledTime: this.beginWorkDateDisabled,
              },
            },
            {
              type: 'fullDateTime',
              key: 'endWork',
              label: 'Дата и время окончания работы',
              attributes: {
                format: 'DD.MM.YYYY HH:mm',
                valueType: 'DD.MM.YYYY HH:mm',
                timeTitleFormat: 'DD.MM.YYYY HH:mm',
                disabledDate: this.endWorkDateDisabled,
                disabledTime: this.endWorkTimeDisabled,
              },
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    this.getCommonList({ name: 'EmployeeAccounts' }).then(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),

    beginWorkDateDisabled(date) {
      if (!this.form.endWork) return false;

      const formattedEndWorkDate = this.$moment(this.form.endWork, 'DD.MM.YYYY HH:mm');

      return this.$moment(date).isAfter(formattedEndWorkDate);
    },

    endWorkDateDisabled(date) {
      if (!this.form.beginWork) return false;

      const formattedBeginWorkDate = this.$moment(this.form.beginWork, 'DD.MM.YYYY HH:mm').subtract(1, 'days');

      return this.$moment(date).isBefore(formattedBeginWorkDate);
    },

    endWorkTimeDisabled(date) {
      if (!this.form.beginWork) return false;

      const formattedBeginWorkDate = this.$moment(this.form.beginWork, 'DD.MM.YYYY HH:mm');

      return this.$moment(date).isBefore(formattedBeginWorkDate);
    },
  },
};
</script>
